import React, { useState } from "react";
import PropTypes from "prop-types";
import "./ValuesPage.css"; // Import the CSS file

const BlogItemContainer = ({ data }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleToggle = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <section className="values-section section-py">
            <div className="container">
                <div className="row mb-n7">
                    <h2 className="values-header">Key values of implementing AI in your own industry</h2>
                    <div className="col-lg-12 col-sm-12 mb-7">
                        {data &&
                            data.map((item, index) => (
                                <div key={index} className="mb-4 topic-item">
                                    {/* Topic title */}
                                    <div
                                        className={`topic-title ${
                                            activeIndex === index ? "active" : ""
                                        }`}
                                        onClick={() => handleToggle(index)}
                                    >
                                        {item.title}
                                    </div>

                                    {/* Topic content with transition */}
                                    <div
                                        className={`topic-content ${
                                            activeIndex === index
                                                ? "content-show"
                                                : "content-hide"
                                        }`}
                                    >
                                        {item.content}
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

BlogItemContainer.propTypes = {
    data: PropTypes.array.isRequired,
};

export default BlogItemContainer;
