import { useEffect, useRef } from "react";
import Button from "../../components/button";
import SectionTitle from "../../components/section-title";
import Parallax from "parallax-js";

const AboutContainer = () => {
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);

    return (
        <div className="about-us position-relative">
            <div className="container">
                <div className="row mb-n7 align-items-center">
                    <div className="col-xl-5 col-md-6 offset-xl-1 mb-7">
                        <div className="about-content">
                            <SectionTitle
                                classOption="title-section"
                                subTitle="ABOUT US"
                                title="Leading <span class='text-primary'>
                                    AI Solutions for Business Growth</span>"
                            />
                            <p className="mb-5">
                                At Heuro, we are a leading provider of cutting-edge AI solutions that drive business growth and enable our clients to thrive in the digital age. Our focus on synthetic data generation, large language models, and intelligent automation allows us to build AI solutions that address the unique challenges of each industry we serve.
                            </p>
                            <p className="mb-5">
                                We understand that every business is unique, and we work closely with our clients to develop customized AI solutions that meet their specific needs. Our team of experts has extensive experience in the field of AI and is committed to delivering innovative and effective solutions that deliver measurable results.
                            </p>
                            <p className="high-light mb-8">
                                We believe that the future of industries lies in the intelligent use of data and technology, and we are committed to helping our clients harness the full potential of AI to achieve their goals. Whether you're looking to enhance your operations, make data-driven decisions, or stay ahead of the competition, Heuro has the expertise and technology to help you succeed.
                            </p>
                            <p className="high-light mb-8">
                                We are passionate about using AI to drive business growth and enable our clients to thrive in the digital age. Contact us today to learn more about how we can help you achieve your goals.
                            </p>
                            <Button
                                classOption="btn btn-lg btn-dark btn-hover-dark"
                                text="Contact Us"
                                path="/contact"
                            />
                        </div>
                    </div>
                    <div className="col-xl-6 order-lg-first col-md-6 mb-7">
                        <div
                            className="about-photo scene text-center text-lg-left"
                            id="scene"
                            ref={sceneEl}
                        >
                            <div data-depth="0.2">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/about/2.png`}
                                    alt="about"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutContainer;
