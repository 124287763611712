import React, { Fragment } from "react";
import { useForm } from "react-hook-form";

const ContactForm = () => {
    const { register, formState: {errors} } = useForm({
        mode: "onBlur",
    });
    return (
        <Fragment>
            <form
                id="contactForm"
                className="row"
                action="https://getform.io/f/bwnngvda"
                method="POST"
            >
                <div className="col-12 col-sm-6 mb-7">
                <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Your Name*"
                    {...register("name", {
                        required: "Name is required",
                    })}
                />
                    {errors.name && <p>{errors.name.message}</p>}
                </div>
                <div className="col-12 col-sm-6 mb-7">
                <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Your Email*"
                    {...register("email", {
                        required: "Email is required",
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "invalid email address",
                        },
                    })}
                />

                    {errors.email && <p>{errors.email.message}</p>}
                </div>

                <div className="col-12 mb-9">
                <textarea
                    className="form-control massage-control"
                    name="message"
                    id="message" // Fixed the ID to be consistent with the name
                    cols="30"
                    rows="10"
                    placeholder="Message"
                    {...register("message", {
                        required: "Message is required",
                    })}
                />

                    {errors.message && <p>{errors.message.message}</p>}
                </div>
                <div className="col-12">
                    <button
                        id="contactSubmit"
                        type="submit"
                        className="btn btn-dark btn-hover-dark"
                        data-complete-text="Well Done!"
                    >
                        Send Message
                    </button>
                </div>
            </form>
        </Fragment>
    );
};

export default ContactForm;
