import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../components/logo";
import SocialIcon from "../../components/social-icon";

const Footer = () => {

    const services = [
        {
            id: 1,
            title: "Enterprise AI Solutions",
        },
        {
            id: 2,
            title: "Specialized AI Technologies",
        },
        {
            id: 3,
            title: "Business Process Automation",
        },
        {
            id: 4,
            title: "Strategic Planning",
        },
        {
            id: 5,
            title: "Generative AI",
        }
    ];

    const quickLinks = [
        {
            id: 1,
            title: "Chat Guruji",
            url: "https://guruji.chat/",
        },
        {
            id: 2,
            title: "Twingen",
            url: "https://twingen.heuro.in/",
        },
        {
            id: 3,
            title: "Listing Genie",
            url: "https://listing-genie.heuro.in/",
        },
        {
            id: 4,
            title: "LinkedIn",
            url: "https://www.linkedin.com/company/heuro/?viewAsMember=true",
        },
        {
            id: 5,
            title: "About Us",
            url: `${process.env.PUBLIC_URL}/about`,
        },
        {
            id: 6,
            title: "Contact Us",
            url: `${process.env.PUBLIC_URL}/contact`,
        }
    ];

    return (
        <footer className="footer-section">
            <div className="footer-top position-relative">
                <img
                    className="footer-shape"
                    src={process.env.PUBLIC_URL + "/images/footer/1.png"}
                    alt="shape"
                />
                <div className="container">
                    <div className="row mb-n7">
                        <div className="col-lg-3 col-sm-6 mb-7">
                            <div className="footer-widget">
                                <Logo
                                    classOption="footer-logo mb-9"
                                    image={`${process.env.PUBLIC_URL}/images/logo/logo-footer.png`}
                                />
                                {/* <p>
                                    Pleasure rationally encounter consequences
                                    that extremely painful. Nor again is there
                                </p> */}
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 mb-7 offset-xl-1">
                            <div className="footer-widget">
                                <h4 className="title">Quick Links</h4>
                                <ul className="footer-menu">
                                {quickLinks.map((item) => (
                                    <li key={item.id}>
                                        {item.url.startsWith("https") ? (
                                            <a
                                                className="footer-link"
                                                href={item.url}
                                                target="_blank" // opens the external link in a new tab
                                                rel="noopener noreferrer" // security feature when opening in a new tab
                                            >
                                                {item.title}
                                            </a>
                                        ) : (
                                            <Link
                                                className="footer-link"
                                                to={item.url}
                                            >
                                                {item.title}
                                            </Link>
                                        )}
                                    </li>
                                ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 mb-7">
                            <div className="footer-widget">
                                <h4 className="title">Services</h4>
                                <ul className="footer-menu">
                                {services.map((service) => (
                                    <li key={service.id}>
                                        <Link
                                            className="footer-link"
                                            to={`${process.env.PUBLIC_URL}/service-details/${service.id}`}
                                        >
                                            {service.title}
                                        </Link>
                                    </li>
                                ))}        
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 mb-7">
                            <div className="footer-widget">
                                <h4 className="title">Contact info</h4>
                                <p>
                                India
                                </p>
                                <ul className="address">
                                    <li>
                                        <a
                                            className="address-link"
                                            href="tel:+12354569874"
                                        >
                                            +91 9897940807
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="address-link"
                                            href="mailto:info@example.com"
                                        >
                                            akshaybansal@gmail.com
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copy-right-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright-info text-center">
                                <p>
                                    Copyright &copy; 2024 By{" "}
                                    <a
                                        href="https://themeforest.net/user/codecarnival/portfolio"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Heuro
                                    </a>
                                    , All Rights Reserved
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
