import React from "react";
import Newsletter from "../../../components/newsletter";
import SectionTitle from "../../../components/section-title";
import SocialIcon from "../../../components/social-icon";

const NewsletterArea = () => {
    return (
        <div className="news-letter-section section-pb">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-xl-6 mx-auto">
                        <SectionTitle
                            classOption="title-section mb-10 text-center"
                            subTitle="NEWSLETTER"
                            title="Subscribe <span class='text-primary'>Newsletter</span> &amp;
                            <br class='d-none d-xl-block' />get latest update..."
                        />
                    </div>
                    <div className="col-xl-8 col-lg-10 mx-auto" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <a className="btn btn-dark btn-hover-dark linkedin-btn" href="https://www.linkedin.com/build-relation/newsletter-follow?entityUrn=7092108944688463872" target="_blank">
                    <i className="icofont-linkedin me-1"></i> Subscribe on LinkedIn
                    </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsletterArea;
