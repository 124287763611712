import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Button from "../button";

const IconBox = ({ data }) => {
    return (
        <div className={data.className}>
            <div className="feature-card bg-light">
                <span className="card-shape card-shape-light"></span>
                <span className="card-shape card-shape-dark"></span>
                <img
                    className="logo"
                    src={process.env.PUBLIC_URL + data.icon}
                    alt=" feature logo"
                    style={{ width: "75px" }}
                />
                <h4 className="title my-6">
                        {data.title}
                </h4>
                <p>{data.excerpt}</p>
                <a
                    className="btn btn-sm animated delay1 btn-dark btn-hover-dark me-4 mb-3 mb-sm-0"
                    href={data.url}
                    target="_blank" // opens the external link in a new tab
                    rel="noopener noreferrer" // security feature when opening in a new tab
                >
                    Try this
                </a>
            </div>
        </div>
    );
};

IconBox.propTypes = {
    data: PropTypes.object,
};

export default IconBox;
