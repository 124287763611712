import { useEffect, useRef } from "react";
import Button from "../../components/button";
import SectionTitle from "../../components/section-title";
import Parallax from "parallax-js";

const PageNotFoundContainer = () => {
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);

    return (
        <div className="page-not-found position-relative">
            <div className="container">
                <div className="row mb-n7 align-items-center">
                    <div className="col-xl-5 col-md-6 offset-xl-1 mb-7">
                        <div className="not-found-content">
                            <SectionTitle
                                classOption="title-section"
                                subTitle="PAGE NOT FOUND"
                                title="Oops! <span class='text-danger'>404</span>"
                            />
                            <p className="mb-5">
                                Sorry, the page you are looking for does not exist.
                                It might have been removed, renamed, or did not exist in the first place.
                            </p>
                            <p className="mb-5">
                                Please check the URL for any mistakes or return to the homepage.
                            </p>
                            <Button
                                classOption="btn btn-lg btn-dark btn-hover-dark"
                                text="Go to Home"
                                path="/"
                            />
                        </div>
                    </div>
                    <div className="col-xl-6 order-lg-first col-md-6 mb-7">
                        <div
                            className="not-found-photo scene text-center text-lg-left"
                            id="scene"
                            ref={sceneEl}
                        >
                            <div data-depth="0.2">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/404/404.png`} // Replace with your image path
                                    alt="Page Not Found"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageNotFoundContainer;
