import { useEffect, useRef } from "react";
import Button from "../../../components/button";
import SectionTitle from "../../../components/section-title";
import Parallax from "parallax-js";

const HomeAboutContainer = () => {
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);
    return (
        <div className="about-us position-relative">
            <div className="container">
                <div className="row mb-n7">
                    <div className="col-xl-5 col-lg-6 mb-7">
                        <div className="about-content">
                            <SectionTitle
                                classOption="title-section"
                                subTitle="ABOUT US"
                                title="Leading <span class='text-primary'>
                                    AI Solutions for Business Growth
                                </span>"
                            />
                            <p className="mb-5">
                            Heuro is a leading provider of cutting-edge AI solutions 
                            that drive business growth and enable clients to thrive in the digital age. 
                            The company specializes in synthetic data generation, large language models, 
                            and intelligent automation, offering customized AI solutions tailored to the 
                            unique needs of each industry. Their team of experts is committed to delivering 
                            innovative and effective solutions that produce measurable results.
                            </p>
                            <p className="high-light mb-8">
                            Heuro believes that the future of industries lies in the intelligent use of 
                            data and technology. They are passionate about using AI to drive business growth 
                            and help clients make data-driven decisions and stay ahead of the competition. 
                            The company encourages businesses to contact them to learn more about how they can 
                            achieve their goals through AI solutions.
                            </p>
                            <Button
                                classOption="btn btn-lg btn-dark btn-hover-dark"
                                text="Contact Us"
                                path="/contact"
                            />
                        </div>
                    </div>
                    <div className="col-xl-6 order-first order-lg-last col-lg-6 mb-7 offset-xl-1">
                        <div
                            className="about-photo scene text-center text-lg-left"
                            id="scene"
                            ref={sceneEl}
                        >
                            <div data-depth="0.2">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/about/1.png`}
                                    alt="about"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img
                className="about-shape"
                src={`${process.env.PUBLIC_URL}/images/about/shape.png`}
                alt="bg-shape"
            />
        </div>
    );
};

export default HomeAboutContainer;
